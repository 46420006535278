const HEADER_NAME = 'consent-policy'

const mappingFromCookie = {
    func: 'functional',
    anl: 'analytics',
    adv: 'advertising',
    dt3: 'dataToThirdParty',
    ess: 'essential'
}

function toHeaderFormat(policy) {
    const raw = {}
    for (const [cookieKey, policyField] of Object.entries(mappingFromCookie)) {
        raw[cookieKey] = policy[policyField] ? 1 : 0
    }

    return encodeURIComponent(JSON.stringify(raw))
}

module.exports = {
    getPolicyHeaderObject: (isWixSite, defaultPolicyDetails) => {
        if (typeof window !== 'undefined' && window.consentPolicyManager) {
            return window.consentPolicyManager._getConsentPolicyHeader()
        }
        if (!isWixSite) {
            return {}
        }
        return {
            [HEADER_NAME]: toHeaderFormat(defaultPolicyDetails.policy)
        }
    },
    getCurrentPolicy: defaultPolicyDetails => {
        if (typeof window !== 'undefined' && window.consentPolicyManager) {
            return window.consentPolicyManager.getCurrentConsentPolicy()
        }
        return defaultPolicyDetails
    }
}
